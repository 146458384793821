import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
// import Slider from 'react-slick'

import SeoHeader from '../components/SeoHeader'
import Hero from '../components/Hero'
// import Avatar from '../components/Avatar'
import BookADemoHero from '../components/BookADemoHero'

export default function Story({ data }) {
  return (
    <React.Fragment>
      <SeoHeader title="Our Story">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </SeoHeader>
      <Hero
        imgSrc={data.shamalWinds}
        title="our story"
        subtitle="Named after the powerful northwesterly wind that blows over the Gulf during summer, changing landscapes and causing sandstorms. Shamal, derived from its climate, aims to revolutionize working gear for those who toil in extreme hot climates. While we continue to enjoy the benefits of our developed cities, giving special care for the work force building it in extreme hot regions is a topic often over-looked. Despite the advancements achieved, this pivotal aspect of our modern life is yet to be addressed. Shamal is leading the cause by offering superior working gear for hot climates."
        right
      />
      <div className="breakup clearfix" />
      <Hero
        mainTitle="our purpose."
        imgSrc={data.imageTwo}
        subtitle="Shamal aims to enhance effectiveness by providing workers with a durable gear that gives a cooling effect for improved productivity."
      />
      <div className="breakup clearfix" />
      <Hero
        mainTitle="our future."
        imgSrc={data.imageThree}
        subtitle="ENSURING A HARDWORKING ECOSYSTEM."
        trailingText="While the specialized work wear marks our first milestone, the future promises a full range of innovative gear and a complete ecosystem for the welfare of workers. The ecosystem is designed to support companies in implementing best practices in the industry, optimizing the impact of workers on businesses."
        right
      />
      <div className="breakup clearfix" />
      {/* <h2 className="uppercase font-medium px-8 text-xl mb-4">
        Meet the minds behind Shamal.
      </h2>
      <div className="px-8 mb-10">
        <Slider
          infinite={true}
          accessibility={true}
          pauseOnFocus={true}
          dots={true}
          arrows={true}
          autoplay={true}
          autoplaySpeed={3000}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          className="text-center"
        >
          <Avatar
            img={data.imageThree}
            name="samer coucair"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."
            title="Director, CE-Creates"
          />
          <Avatar
            img={data.imageThree}
            name="ummalt ibragimov"
            description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil."
            title="Project Manager, Shamal"
          />
        </Slider>
      </div>
      <div className="breakup clearfix" /> */}
      <BookADemoHero image={data.bookADemo} />
    </React.Fragment>
  )
}

Story.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    shamalWinds: file(relativePath: { eq: "img/ShamalWinds.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "img/_AU_0608.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "img/_AU_0836.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bookADemo: file(relativePath: { eq: "img/_AU_0569.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
